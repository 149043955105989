import { useCallback, useState } from 'react';
import { API } from "aws-amplify";
import { getAuthToken } from '../utils/AuthUtils';

export default function useSearchUsers() {
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const lookupUsers = useCallback(async (searchPhrase) => {
        setLoadingUsers(true);

        try {
            const token = await getAuthToken();
            const response = await API.get("AnnualCompensations", "/users", {
                headers: { "Token": token },
                queryStringParameters: { searchPhrase: searchPhrase.toLowerCase() }
            });
            setUserSearchResults(response);
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setLoadingUsers(false);
        }
    }, []);

    return {
        userSearchResults,
        loadingUsers,
        lookupUsers
    };
}