import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import ClientMetadataLookupInput from '../../../CommonComponents/ClientMetadataLookupInputComponent/ClientMetadataLookupInput';
import UserLookupInput from '../../../CommonComponents/UserLookupInputComponent/UserLookupInput';
import { BILLABLE_TYPES, HUMAN_READABLE_BILLABLE_TYPES } from '../../../Constants';
import { getAuthToken } from '../../../utils/AuthUtils';

import './styles.scss';

const CUSOTM_TEMPLATE_ID = 'Custom';

const BillingDataModal = ({ show, onClose, data, onRecordUpdated, mode }) => {
    const [billingRecord, setBillingRecord] = useState(data || {});
    const [templateNameList, setTemplateNameList] = useState([]);

    const [loadingStatus, setLoadingStatus] = useState(false);

    const [error, setError] = useState("");
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);
    const [clientOwnerNameErrorMessage, setClientOwnerNameErrorMessage] = useState("");

    const [success, setSuccess] = useState("");
    const [successDisplayStatus, setSuccessDisplayStatus] = useState(false);

    const [billingTypeValid, setBillingTypeValid] = useState(true);
    const [negotiatedRateValid, setNegotiatedRateValid] = useState(true);
    const [dateAccessedValid, setDateAccessedValid] = useState(true);
    const [templateNameValid, setTemplateNameValid] = useState(true);
    const [clientNameValid, setClientNameValid] = useState(true);
    const [clientOwnerNameValid, setClientOwnerNameValid] = useState(true);
    const [downloadUserEmailValid, setDownloadUserEmailValid] = useState(true);
    const [noCompaniesAccessedValid, setNoCompaniesAccessedValid] = useState(true);
    const [adminNotesValid, setAdminNotesValid] = useState(true);

    const [downloadUserLookupTerm, setDownloadUserLookupTerm] = useState("");
    const [clientOwnerLookupTerm, setClientOwnerLookupTerm] = useState("");
    const [clientNameLookupTerm, setClientNameLookupTerm] = useState("");

    useEffect(() => {
        if (mode === 'edit' && data) {
            setBillingRecord(data);
            setDownloadUserLookupTerm(data.userEmail);
            setClientOwnerLookupTerm(data.clientOwnerName);
            setClientNameLookupTerm(data.clientName);
        } else if (mode === 'create') {
            const retrieveTemplateNames = async () => {
                setLoadingStatus(true);
                const authToken = await getAuthToken();
                API.get("AnnualCompensations",
                    "/research-analysis/template-names",
                    {
                        headers: {
                            'Token': authToken
                        }
                    }).then(response => {
                        setTemplateNameList(response.data);
                        setLoadingStatus(false);
                    }
                    ).catch(error => {
                        setLoadingStatus(false);
                        setErrorDisplayStatus(true);
                        if (error?.response?.data?.message) {
                            setError(error.response.data.message);
                        } else {
                            setError("An error occured while retrieving template names. Please try again later.");
                        }
                    });
            }
            retrieveTemplateNames();
            setBillingRecord({
                fullDateAccessed: new Date().toISOString(),
                clientName: '',
                clientOwnerName: '',
                clientOwnerEmail: '',
                clientOwnerUserId: '',
                userId: '',
                userEmail: '',
                templateId: '',
                templateName: '',
                billableType: '',
                numberOfCompaniesAccessed: 1,
                negotiatedRate: 0,
                userNotes: '',
                adminNotes: '',
                nsId: ''
            });
        }
    }, [mode, data]);

    const handleClose = () => {
        setSuccessDisplayStatus(false);
        setErrorDisplayStatus(false);
        setError("");
        setSuccess("");
        setBillingRecord({});
        setClientNameValid(false);
        setClientOwnerNameValid(false);
        setDownloadUserEmailValid(false);
        setDownloadUserLookupTerm("");
        setClientOwnerLookupTerm("");
        setClientNameLookupTerm("");
        onClose();
        resetState();
    };

    const handleSetClientMetadata = (clientOwnerMetadata) => {
        if (!clientOwnerMetadata.companyName) {
            setClientOwnerNameValid(false);
            setClientOwnerNameErrorMessage("Client Owner not found");
            return;
        }
        if (clientOwnerMetadata.companyName) {
            setClientOwnerLookupTerm(clientOwnerMetadata?.clientOwner);
            setClientNameLookupTerm(clientOwnerMetadata.companyName);
            const updatedBillingRecord = {
                ...billingRecord,
                clientName: clientOwnerMetadata.companyName,
                nsId: clientOwnerMetadata.nsId,
                clientOwnerName: clientOwnerMetadata?.clientOwner,
                clientOwnerEmail: clientOwnerMetadata?.clientOwnerEmail,
                clientOwnerUserId: clientOwnerMetadata?.clientOwnerId
            };
            setBillingRecord(updatedBillingRecord);
        }
    };

    const handleSetSelectedClientOwnerNameRecord = (clientOwnerNameRecord) => {
        if (clientOwnerNameRecord.userName && clientOwnerNameRecord.userEmail && clientOwnerNameRecord.userId) {
            setBillingRecord({
                ...billingRecord,
                clientOwnerName: clientOwnerNameRecord.userName,
                clientOwnerEmail: clientOwnerNameRecord.userEmail,
                clientOwnerUserId: clientOwnerNameRecord.userId
            });
            setClientOwnerLookupTerm(clientOwnerNameRecord.userName);
        }
    };

    const handleSetBillableType = (billableType) => {
        setBillingRecord({ ...billingRecord, billableType });
    };

    const handleChangeNumCompaniesAccessed = (number) => {
        setBillingRecord({ ...billingRecord, numberOfCompaniesAccessed: number });
    };

    const handleSetAdminNotes = (adminNotes) => {
        setBillingRecord({ ...billingRecord, adminNotes });
    };

    const handleSettingDownloadUserInformation = (userDataRecord) => {
        const updatedBillingRecord = { ...billingRecord };
        updatedBillingRecord.userId = userDataRecord.userId;
        updatedBillingRecord.userEmail = userDataRecord.userEmail;
        updatedBillingRecord.userName = userDataRecord.userName;

        setBillingRecord(updatedBillingRecord);
    };

    const handleTemplateSelection = (e) => {
        const templateId = e.target.value;
        if (templateId === CUSOTM_TEMPLATE_ID) {
            setBillingRecord({ ...billingRecord, templateId, templateName: 'Custom' });
            return;
        }
        const templateName = templateNameList.find(template => template.templateId === templateId)?.templateName;
        setBillingRecord({ ...billingRecord, templateId, templateName });
    }

    const handleSettingClientName = (clientName) => {
        setClientNameLookupTerm(clientName);
    };

    const resetState = () => {
        setSuccessDisplayStatus(false);
        setErrorDisplayStatus(false);
        setError("");
        setSuccess("");
        setDownloadUserEmailValid(true);
        setClientNameValid(true);
        setClientOwnerNameValid(true);
        setDownloadUserEmailValid(true);
        setNoCompaniesAccessedValid(true);
        setBillingTypeValid(true);
        setNegotiatedRateValid(true);
        setDateAccessedValid(true);
        setTemplateNameValid(true);
        setAdminNotesValid(true);
    };

    const handleSubmit = async (e) => {
        resetState();
        if (!checkBillingRecordValidity()) {
            return;
        }

        try {
            setLoadingStatus(true);
            const token = await getAuthToken();
            const apiMethod = mode === 'edit' ? 'put' : 'post';
            await API[apiMethod]("AnnualCompensations", '/billing-data', {
                body: billingRecord,
                headers: {
                    "Content-Type": "application/json",
                    "Token": token
                }
            }).then(response => {
                setLoadingStatus(false);
                setSuccessDisplayStatus(true);
                setSuccess(response.message);
                onRecordUpdated();
                handleClose();
            }).catch(error => {
                console.log(error);
                setLoadingStatus(false);
                setErrorDisplayStatus(true);
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred while modifying template data. Please try again later.");
                }
            });
        } catch (error) {
            console.log(error);
            setLoadingStatus(false);
            setErrorDisplayStatus(true);
            setError("Exception occurred updating billing record");
        }
    };

    const checkClientNameValidity = () => {
        if (!billingRecord.clientName || billingRecord.clientName.length < 2) {
            setClientNameValid(false);
            return false;
        }

        if ((billingRecord.billableType === BILLABLE_TYPES.billable
            || billingRecord.billableType === BILLABLE_TYPES.customBillable)
            && (!billingRecord.clientName || billingRecord.clientName.length < 2)) {
            setClientNameValid(false);
            return false;
        }
        return true;
    }

    const validateClientOwnerInfo = () => {
        if (!billingRecord.clientOwnerName || !billingRecord.clientOwnerEmail) {
            setClientOwnerNameValid(false);
            setClientOwnerNameErrorMessage("Client Owner not found");
            return false;
        }
        if ((billingRecord.billableType === BILLABLE_TYPES.billable
            || billingRecord.billableType === BILLABLE_TYPES.customBillable)
            && (!billingRecord.clientOwnerName || !billingRecord.clientOwnerEmail)) {
            setClientOwnerNameValid(false);
            setClientOwnerNameErrorMessage("Client Owner not found");
            return false;
        }
        return true;
    }

    const checkBillingRecordValidity = () => {
        let isValid = true;
        if (!billingRecord.billableType || billingRecord.billableType === '') {
            setBillingTypeValid(false);
            isValid = false;
        }

        if ((!billingRecord.numberOfCompaniesAccessed || billingRecord.numberOfCompaniesAccessed < 1)
            && billingRecord.billableType !== BILLABLE_TYPES.customBillable) {
            setNoCompaniesAccessedValid(false);
            isValid = false;
        }

        if (!billingRecord.fullDateAccessed || billingRecord.fullDateAccessed === '') {
            setDateAccessedValid(false);
            isValid = false;
        }

        if (!billingRecord.templateId || billingRecord.templateId === ''
            || !billingRecord.templateName || billingRecord.templateName === '') {
            setTemplateNameValid(false);
            isValid = false;
        }

        // template id is custom then billable type should be custom and negotiated rate should be greater than 0
        if (billingRecord.billableType === BILLABLE_TYPES.customBillable
            && (!billingRecord.negotiatedRate || billingRecord.negotiatedRate <= 0)) {
            setNegotiatedRateValid(false);
            isValid = false;
            setError("Negotiated rate should be greater than 0 for custom billable type");
            setErrorDisplayStatus(true);
        }

        if (billingRecord.templateId === CUSOTM_TEMPLATE_ID && billingRecord.billableType !== BILLABLE_TYPES.customBillable) {
            setBillingTypeValid(false);
            isValid = false;
            setError("Billable type should be custom for custom template");
            setErrorDisplayStatus(true);
        }

        if (!billingRecord.adminNotes || billingRecord.adminNotes.length < 3) {
            setAdminNotesValid(false);
            isValid = false;
        }

        if (!billingRecord.userEmail || billingRecord.userEmail.length < 2) {
            setDownloadUserEmailValid(false);
            isValid = false;
        }

        if (!checkClientNameValidity()) {
            isValid = false;
        }

        if (!validateClientOwnerInfo()) {
            isValid = false;
        }

        return isValid;
    }

    return (
        <Modal show={show} onHide={handleClose} size='lg' centered>
            <div className="billing-record-container">
                <Form
                    noValidate
                    onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); handleSubmit(e); }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{mode === 'edit' ? 'Edit Billing Record' : 'Create Billing Record'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert show={errorDisplayStatus} variant="danger" dismissible onClose={() => setErrorDisplayStatus(false)}>
                            {error}
                        </Alert>
                        <Alert show={successDisplayStatus} variant="success" dismissible onClose={() => setSuccessDisplayStatus(false)}>
                            {success}
                        </Alert>
                        {loadingStatus ? (
                            <div className="loading">Loading...<Spinner as="span" animation="grow" size="sm" aria-hidden="true" /></div>
                        ) : (
                            <div className="billing-record-form">
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="billableType">
                                            <Form.Label>Billable Type</Form.Label>
                                            <Form.Select value={billingRecord.billableType} required onChange={(e) => handleSetBillableType(e.target.value)} isInvalid={!billingTypeValid}>
                                                <option value="">Select Billable Type</option>
                                                <option value={BILLABLE_TYPES.billable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.billable]}</option>
                                                <option value={BILLABLE_TYPES.customBillable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.customBillable]}</option>
                                                <option value={BILLABLE_TYPES.nonBillableRFP}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableRFP]}</option>
                                                <option value={BILLABLE_TYPES.nonBillableOther}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableOther]}</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">Please select a billable type</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {billingRecord.billableType !== BILLABLE_TYPES.customBillable ? <Col md={6}>
                                        <Form.Group controlId="dateAccessed">
                                            <Form.Label>Date Data Accessed</Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={billingRecord.fullDateAccessed || ''}
                                                required
                                                onChange={(e) => setBillingRecord({ ...billingRecord, fullDateAccessed: e.target.value })}
                                                isInvalid={!dateAccessedValid}
                                            />
                                            <Form.Control.Feedback type="invalid">Please enter a date</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> :
                                        <Col md={6}>
                                            <Form.Group controlId="negotiatedRate">
                                                <Form.Label>Negotiated Rate</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={billingRecord.negotiatedRate || 0}
                                                    required
                                                    onChange={(e) => setBillingRecord({ ...billingRecord, negotiatedRate: Number(e.target.value) })}
                                                    isInvalid={!negotiatedRateValid}
                                                />
                                            </Form.Group>
                                        </Col>}
                                </Row>
                                {billingRecord.billableType === BILLABLE_TYPES.customBillable &&
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="dateAccessed">
                                                <Form.Label>Date Data Accessed</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={billingRecord.fullDateAccessed || ''}
                                                    required
                                                    onChange={(e) => setBillingRecord({ ...billingRecord, fullDateAccessed: e.target.value })}
                                                    isInvalid={!dateAccessedValid}
                                                />
                                                <Form.Control.Feedback type="invalid">Please enter a date</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>}
                                <Row>
                                    {mode === 'create' && <Col md={6}>
                                        <Form.Group controlId="templateName">
                                            <Form.Label>Template Name</Form.Label>
                                            <Form.Select
                                                value={billingRecord.templateId || ''}
                                                required
                                                onChange={e => handleTemplateSelection(e)}
                                                isInvalid={!templateNameValid}                                                >
                                                <option value="">Select Template</option>
                                                <option value={CUSOTM_TEMPLATE_ID}>Custom</option>
                                                {templateNameList.map(template => <option key={template.templateId} value={template.templateId}>{template.templateName}</option>)}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">Please select a template</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>}
                                    <Col md={6}>
                                        <UserLookupInput
                                            value={downloadUserLookupTerm}
                                            handleValueChange={setDownloadUserLookupTerm}
                                            displayProperty="userEmail"
                                            noValidate={false}
                                            isValueValid={downloadUserEmailValid}
                                            handleUserRecordSelection={(record) => handleSettingDownloadUserInformation(record)}
                                            placeholder='Enter User Name and Select'
                                            inputLabel="Download User Email"
                                            customErrorMessage="User is not valid"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <ClientMetadataLookupInput
                                            value={clientNameLookupTerm}
                                            handleValueChange={handleSettingClientName}
                                            noValidate={false}
                                            isValueValid={clientNameValid}
                                            handleClientMetadataRecordSelection={handleSetClientMetadata}
                                            placeholder='Enter Client Name and Select'
                                            inputLabel="Client Name"
                                            customErrorMessage="Client not found"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <UserLookupInput
                                            value={clientOwnerLookupTerm}
                                            handleValueChange={setClientOwnerLookupTerm}
                                            noValidate={false}
                                            isValueValid={clientOwnerNameValid}
                                            handleUserRecordSelection={handleSetSelectedClientOwnerNameRecord}
                                            placeholder='Enter Client Owner Name and Select'
                                            inputLabel="Client Owner Name"
                                            customErrorMessage={clientOwnerNameErrorMessage}
                                        />
                                    </Col>
                                </Row>
                                {billingRecord.billableType !== BILLABLE_TYPES.customBillable && <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="numberOfCompaniesAccessed">
                                            <Form.Label>No. Companies</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={billingRecord.numberOfCompaniesAccessed}
                                                required
                                                onChange={e => {
                                                    const value = Number(e.target.value);
                                                    if (value < 1) {
                                                        return;
                                                    }
                                                    handleChangeNumCompaniesAccessed(value);
                                                }}
                                                isInvalid={!noCompaniesAccessedValid}
                                            />
                                            <Form.Control.Feedback type="invalid">Please enter a number</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>}
                                {mode === 'edit' && <Row>
                                    <Form.Group controlId="userNotes">
                                        <Form.Label>User Notes</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            value={billingRecord.userNotes || ''}
                                            readOnly={mode === 'edit'}
                                        />
                                    </Form.Group>
                                </Row>}
                                <Row>
                                    <Form.Group controlId="adminNotes">
                                        <Form.Label>Admin Notes</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            required
                                            isInvalid={!adminNotesValid}
                                            value={billingRecord.adminNotes || ''}
                                            onChange={(e) => handleSetAdminNotes(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter admin notes</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="primary" disabled={loadingStatus} type="submit">{mode === 'edit' ? 'Save' : 'Create'}</Button>
                    </Modal.Footer>
                </Form>
            </div>
        </Modal>
    );
};

BillingDataModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
    onRecordUpdated: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['edit', 'create']).isRequired
};

export default BillingDataModal;