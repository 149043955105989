import { Form } from "react-bootstrap";
import { BILLABLE_TYPES, HUMAN_READABLE_BILLABLE_TYPES } from "../../Constants";

export const billableTypeComponent = (userHistoryRecordsStateMap, uniqueId, cell, handleBillableTypeChange) => {
    return <div key={uniqueId}>
        {userHistoryRecordsStateMap.get(uniqueId) ?
            <div>
                <Form.Control as="select" required={true} value={cell} onChange={(e) => handleBillableTypeChange(e, uniqueId)}>
                    <option value={BILLABLE_TYPES.billable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.billable]}</option>
                    <option value={BILLABLE_TYPES.customBillable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.customBillable]}</option>
                    <option value={BILLABLE_TYPES.nonBillableRFP}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableRFP]}</option>
                    <option value={BILLABLE_TYPES.nonBillableOther}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableOther]}</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please select a billing type.
                </Form.Control.Feedback>
            </div> : HUMAN_READABLE_BILLABLE_TYPES[cell]}
    </div>;
}