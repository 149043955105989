import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import ClientNameForDataExport from "../../CommonComponents/DataExportConfirmationModal/ClientNameForDataExport";
import UploadModal from '../../CommonComponents/UploadModal/UploadModal';
import GroupVisibleComponent from '../../GroupVisibleComponent';

import './styles.scss';

function ToolbarActions({ searching, selectedTemplate, selecteCompIds, handleError, successfulDownload }) {
    const [showClientNameModal, setShowClientNameModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);

    const handleDonwloadButtonClick = () => {
        if (selecteCompIds?.size > 0) {
            setShowClientNameModal(true);
            successfulDownload(false);
            handleError("");
        } else {
            handleError("Please select at least one row to download");
        }
    }

    if (!selectedTemplate)
        return null;
    return (
        <div className="toolbar-actions mt-2 pb-2">
            {selectedTemplate ?
                <>
                    {searching ? <Button variant="primary toolbar-btn" disabled>
                        <outout>Searching...</outout>
                        <Spinner as="span" animation="grow" size="sm" aria-hidden="true" />
                    </Button> : <Button variant="primary toolbar-btn" type="submit">
                        Search<i className="fa fa-search"></i>
                    </Button>}
                    <Button
                        variant="btn btn-secondary btn-download toolbar-btn"
                        onClick={handleDonwloadButtonClick}>
                        Download<i className="fa fa-download"></i>
                    </Button>
                    <ClientNameForDataExport
                        show={showClientNameModal}
                        onClose={() => setShowClientNameModal(false)}
                        templateTitle={selectedTemplate.templateName}
                        compensationIds={selecteCompIds}
                        selectedTemplate={selectedTemplate}
                    />
                    <GroupVisibleComponent groups={["FWC_Upload"]}>
                        <Button variant="btn btn-secondary btn-upload toolbar-btn" onClick={() => setShowUploadModal(true)}>
                            Upload<i className="fa fa-upload"></i>
                        </Button>
                        <UploadModal
                            templateId={selectedTemplate.templateId}
                            dataType={selectedTemplate.templateName}
                            templateType={selectedTemplate.templateType}
                            show={showUploadModal} onClose={() => setShowUploadModal(false)} />
                    </GroupVisibleComponent>
                </> : null}
        </div>
    );
}

ToolbarActions.propTypes = {
    searching: PropTypes.bool,
    selectedTemplate: PropTypes.object,
    selecteCompIds: PropTypes.object,
    handleError: PropTypes.func,
    successfulDownload: PropTypes.func
};

export default ToolbarActions;