import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { getAuthToken } from '../../utils/AuthUtils';
import { Alert, Badge, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import CompanyRequestsGrid from '../CompanyRequestsGrid/CompanyRequestsGrid';
import { API } from 'aws-amplify';

import './styles.scss';
import DataRequestStatusDropDown from '../DataRequestStatusDropDown/DataRequestStatusDropDown';
import ToolTipWrapper from '../../CommonComponents/ToolTipComponent/ToolTipWrapper';
import DataRequestLinksComponent from '../DataRequestLinksComponent/DataRequestLinksComponent';

export default function EditDataRequestModal({ dataRequestId, show, onClose, onRequestsUpdated }) {

    const [dataRequest, setDataRequest] = useState(null);

    const [loadingStatus, setLoadingStatus] = useState(false);

    const [error, setError] = useState("");
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);

    const [success, setSuccess] = useState("");
    const [successDisplayStatus, setSuccessDisplayStatus] = useState(false);

    const [formIsValidated, setFormIsValidated] = useState(false);
    const [areCompanyRequestsValid, setAreCompanyRequestsValid] = useState(true);

    const [activeTemplates, setActiveTemplates] = useState([]);

    const handleChangingCompanyRequests = (updatedCompanyRequests) => {
        setDataRequest({ ...dataRequest, companiesRequested: updatedCompanyRequests });
    }

    const handleRemoveCompanyRequest = (index) => {
        const updatedCompanyRequests = [...dataRequest.companiesRequested];
        updatedCompanyRequests.splice(index, 1);
        setDataRequest({ ...dataRequest, companiesRequested: updatedCompanyRequests });
    }

    const handleAddCompanyRequest = () => {
        const updatedCompanyRequests = [...dataRequest.companiesRequested];
        updatedCompanyRequests.push({ companyId: '', companyName: '', analysisType: '', hasAlert: false, alertMessage: '' });
        setDataRequest({ ...dataRequest, companiesRequested: updatedCompanyRequests });
    }

    const formatNotifiedDate = (date) => {
        return new Date(date).toLocaleString();
    }

    const handleClose = () => {
        setAreCompanyRequestsValid(true);
        setFormIsValidated(false);
        setSuccessDisplayStatus(false);
        setErrorDisplayStatus(false);
        setDataRequest(null);
        setError("");
        setSuccess("");
        onClose();
    }

    const getDataRequest = async (requestId) => {
        setLoadingStatus(true);
        setErrorDisplayStatus(false);
        setError("");

        const authToken = await getAuthToken();

        const url = `/data-requests/${requestId}`;

        API.get('AnnualCompensations', url, {
            headers: {
                "Content-Type": "application/json",
                "Token": authToken
            }
        }).then(response => {
            setDataRequest(response);
            setLoadingStatus(false);
        }
        ).catch(error => {
            setError(error);
            setErrorDisplayStatus(true);
            setLoadingStatus(false);
        });
    }

    const handleDataRequestSubmission = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!areCompanyRequestsValid) {
            return;
        }

        if (e.currentTarget.checkValidity() === false) {
            e.stopPropagation();
            setFormIsValidated(true);
            return;
        }

        if (dataRequest.companiesRequested.length === 0) {
            setError("Please add at least one company to the request");
            setErrorDisplayStatus(true);
            return;
        }

        setFormIsValidated(true);

        try {
            setLoadingStatus(true);
            await API.put("AnnualCompensations", `/data-requests/${dataRequest.id}`, {
                body: dataRequest,
                headers: {
                    "Content-Type": "application/json",
                    "Token": await getAuthToken()
                }
            });

            setLoadingStatus(false);
            setSuccessDisplayStatus(true);
            setSuccess("Data request updated successfully");
            onRequestsUpdated();
            handleClose();
        } catch (error) {
            setLoadingStatus(false);
            console.log(error);
            setErrorDisplayStatus(true);
            setError("An error occurred while updating the data request. Please try again later.");
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const retrieveTemplateNames = async () => {
                setLoadingStatus(true);
                const authToken = await getAuthToken();
                API.get("AnnualCompensations",
                    "/research-analysis/template-names",
                    {
                        headers: {
                            'Token': authToken
                        }
                    }).then(response => {
                        setActiveTemplates(response.data);
                        setLoadingStatus(false);
                    }
                    ).catch(error => {
                        console.log(error);
                        setLoadingStatus(false);
                    });
            }
            retrieveTemplateNames();
            getDataRequest(dataRequestId);
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRequestId]);

    return (
        <div>
            <Modal show={show} onHide={handleClose} size='xl' centered>
                <div className="edit-data-request-container">
                    <Form noValidate validated={formIsValidated} onSubmit={(e) => handleDataRequestSubmission(e)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Data Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert show={errorDisplayStatus} variant="danger" dismissible onClose={() => setErrorDisplayStatus(false)}>
                                {error}
                            </Alert>
                            <Alert show={successDisplayStatus} variant="success" dismissible onClose={() => setSuccessDisplayStatus(false)}>
                                {success}
                            </Alert>
                            {loadingStatus ? <div className="loading"> Loading...<output><Spinner as="span" animation="grow" size="sm" aria-hidden="true" /> </output></div> :
                                <>
                                    {dataRequest && <div>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="clientCompanyId">
                                                    <Form.Label>Client Company ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Client Company ID"
                                                        value={dataRequest.clientCompanyId}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="clientName">
                                                    <Form.Label>Client Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Client Name"
                                                        value={dataRequest.clientName}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="dateRequested">
                                                    <Form.Label>Date Requested</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Date Requested"
                                                        value={dataRequest.dateRequested}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Deadline</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="date"
                                                        min={new Date().getFullYear() + "-01-01"}
                                                        value={dataRequest.deadline}
                                                        onChange={(e) => setDataRequest({ ...dataRequest, deadline: e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="requestorEmail">
                                                    <Form.Label>Requestor</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Requestor"
                                                        value={dataRequest.requestorEmail}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <DataRequestStatusDropDown
                                                    requestStatus={dataRequest.requestStatus}
                                                    onRequestStatusChange={(status) => setDataRequest({ ...dataRequest, requestStatus: status })}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                {dataRequest.notifiedDate ? <Form.Group controlId="notifiedDate">
                                                    <Form.Label>Notified Date</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={formatNotifiedDate(dataRequest.notifiedDate)}
                                                        readOnly
                                                    />
                                                </Form.Group> : ""}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="notes">
                                                    <Form.Label>Flag</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="flagged-switch"
                                                        checked={dataRequest.flag}
                                                        onChange={(e) => setDataRequest({ ...dataRequest, flag: e.target.checked })}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <DataRequestLinksComponent companyRequests={dataRequest.companiesRequested} />
                                        <div className="company-requests-grid-container">
                                            <div className="company-requests-header">
                                                <div className='company-requests-header__title'>
                                                    <h4>Company Requests</h4>
                                                    <div className="company-requests__header__title__badge">
                                                        <ToolTipWrapper
                                                            id="company-requests-count-tooltip"
                                                            placement="top"
                                                            text="Number of companies in the request"
                                                            element={<Badge variant="primary">{dataRequest.companiesRequested.length}</Badge>}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="company-requests__header__add-button">
                                                    <ToolTipWrapper
                                                        id="add-company-requests-tooltip"
                                                        placement="top"
                                                        text="Add companies to the request"
                                                        element={
                                                            <Button onClick={() => handleAddCompanyRequest()} variant="primary">
                                                                <i className="fa fa-2xl fa-plus-circle"></i>
                                                            </Button>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <CompanyRequestsGrid
                                                companyRequests={dataRequest.companiesRequested}
                                                onCompanyRequestsChange={(updatedCompanyRequests) => handleChangingCompanyRequests(updatedCompanyRequests)}
                                                onRequestValidityChange={setAreCompanyRequestsValid}
                                                activeTemplates={activeTemplates}
                                                onRemoveCompanyRequest={handleRemoveCompanyRequest}
                                            />
                                        </div>
                                    </div>}
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" disabled={loadingStatus} type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

EditDataRequestModal.propTypes = {
    dataRequestId: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRequestsUpdated: PropTypes.func.isRequired
};
