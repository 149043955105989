import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import useSearchUsers from '../../Hooks/useSearchUsers';
import AutoCompletableInput from '../AutoCompletableInputComponent/AutoCompletableInput';

const UserLookupInput = ({
    value,
    handleValueChange,
    noValidate = true,
    displayProperty = "userName",
    isValueValid,
    handleUserRecordSelection,
    inputLabel,
    placeholder = "",
    customErrorMessage = ""
}) => {
    const [hasError, setHasError] = useState(false);

    const {
        userSearchResults,
        loadingUsers,
        lookupUsers
    } = useSearchUsers();

    const handleChange = async (value) => {
        handleValueChange(value);
        if (value && value.length >= 2) {
            try {
                await lookupUsers(value);
            } catch (error) {
                console.error(error);
                setHasError(true);
            }
        }
    };

    const handleSelectResult = useCallback((selectedResultIndex) => {
        const record = userSearchResults[selectedResultIndex];
        handleUserRecordSelection(record);
        handleValueChange(record[displayProperty]);
    }, [userSearchResults, handleUserRecordSelection, handleValueChange, displayProperty]);

    return (
        <div className='user-lookup-input'>
            <AutoCompletableInput
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                isValid={isValueValid}
                noValidate={noValidate}
                label={inputLabel}
                searchResults={userSearchResults}
                uniqueSearchResultPropName={"userId"}
                resultItem={"userName"}
                loadingResults={loadingUsers}
                errorMsg={customErrorMessage}
                handleSelection={(selectedIndex) => handleSelectResult(selectedIndex)}
            />

            {hasError && <Alert variant="danger">Error fetching users</Alert>}
        </div>
    );
};

UserLookupInput.propTypes = {
    handleValueChange: PropTypes.func.isRequired,
    handleUserRecordSelection: PropTypes.func.isRequired,
    displayProperty: PropTypes.string,
    value: PropTypes.string,
    noValidate: PropTypes.bool,
    isValueValid: PropTypes.bool,
    inputLabel: PropTypes.string,
    placeholder: PropTypes.string,
    customErrorMessage: PropTypes.string
};

export default UserLookupInput;