import { API, Analytics } from "aws-amplify";
import { useEffect, useState } from "react";
import { getAuthToken } from "../../utils/AuthUtils";
import { Alert, Button, Spinner, Form } from "react-bootstrap";
import { rowActionsComponent } from "../RowActionsComponent/RowActionsComponent";
import { billableTypeComponent } from "../BillableTypesComponent/BillableTypesComponent";
import SimpleGridComponent from "../../SimpleGridComponent/SimpleGridComponent";
import { BILLABLE_TYPES, HUMAN_READABLE_BILLABLE_TYPES } from "../../Constants";

const API_PATH = "/usage-data/client-owner";

const numberOfCompaniesAccessedComponent = (userHistoryRecordsStateMap, uniqueId, cell, billableType, handleNumberofCompaniesAccessedChange) => {
    return <div key={uniqueId}>
        {userHistoryRecordsStateMap.get(uniqueId) ?
            <div>
                <Form.Control
                    type="text"
                    required={billableType === BILLABLE_TYPES.billable}
                    value={cell || ""}
                    onChange={(e) => handleNumberofCompaniesAccessedChange(e, uniqueId)}
                    isInvalid={cell === "" && billableType === BILLABLE_TYPES.billable} />
                <Form.Control.Feedback type="invalid">
                    Please enter the number of companies accessed.
                </Form.Control.Feedback>
            </div>
            : cell}
    </div>
}

const ClientOwnerDataDownloadHistoryTab = () => {

    const [loadingStatus, setLoadingStatus] = useState(false);

    const [showHeaderFilters, setShowHeaderFilters] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [userHistory, setUserHistory] = useState([]);
    const [error, setError] = useState(null);
    const [showError, setShowError] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);

    const [success, setSuccess] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [userHistoryRecordsStateMap, setUserHistoryRecordsStateMap] = useState(new Map());

    const columns = [
        {
            "key": "actions",
            "name": "Actions",
            "sortable": false,
            "filterable": false,
            "formatter": (_, row) => {
                return (
                    rowActionsComponent(userHistoryRecordsStateMap, row.uniqueId, handleHistoryRecordUpdate, handleRowStateUpdate)
                );
            },
            "fixed": true,
            "width": "100"
        },
        {
            "key": "fullDateAccessed",
            "name": "Date Accessed",
            "sortable": true,
            "filterable": true,
            "fixed": true,
            "width": "150"
        },
        {
            "key": "templateName",
            "name": "Template Name",
            "sortable": true,
            "filterable": true,
            "fixed": true,
            "width": "150"
        },
        {
            "key": "numberOfCompaniesAccessed",
            "name": "No. of Companies Accessed",
            "sortable": true,
            "filterable": true,
            "formatter": (cell, row) => {
                return (
                    numberOfCompaniesAccessedComponent(userHistoryRecordsStateMap, row.uniqueId, cell, row.billableType, handleNumberofCompaniesAccessedChange)
                )
            }
        },
        {
            "key": "billableType",
            "name": "Billing Type",
            "sortable": false,
            "filterable": false,
            "filterType": "dropdown",
            "filterOptions": [
                { "value": BILLABLE_TYPES.billable, "key": HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.billable] },
                { "value": BILLABLE_TYPES.customBillable, "key": HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.customBillable] },
                { "value": BILLABLE_TYPES.nonBillableRFP, "key": HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableRFP] },
                { "value": BILLABLE_TYPES.nonBillableOther, "key": HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableOther] }
            ],
            "formatter": (cell, row) => {
                return (
                    billableTypeComponent(userHistoryRecordsStateMap, row.uniqueId, cell, handleBillableTypeChange)
                );
            }
        },
        {
            "key": "clientName",
            "name": "Client Name",
            "sortable": false,
            "filterable": false
        },
        {
            "key": "email",
            "name": "Accessed By",
            "sortable": false,
            "filterable": false
        },
        {
            "key": "userNotes",
            "name": "User Notes",
            "sortable": false,
            "filterable": false
        }
    ];

    const handleError = (error) => {
        setError(error);
        setShowError(true);
        setSuccess(null);
        setShowSuccess(false);
    }

    const handleNumberofCompaniesAccessedChange = (event, uniqueId) => {
        const updatedUserHistory = [...userHistory];
        const index = updatedUserHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
        updatedUserHistory[index].numberOfCompaniesAccessed = event.target.value;
        setUserHistory(updatedUserHistory);
    }

    const handleBillableTypeChange = (event, uniqueId) => {
        const updatedUserHistory = [...userHistory];
        const index = updatedUserHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
        updatedUserHistory[index].billableType = event.target.value;
        setUserHistory(updatedUserHistory);
    }

    const handleSettingSuccessMessage = (message) => {
        setSuccess(message);
        setShowSuccess(true);
        window.setTimeout(() => {
            setShowSuccess(false);
        }, 3000);
        setError(null);
        setShowError(false);
    }

    const normalizeData = (data) => {
        return data.map(company => {
            const rowRecord = {
                "Template Name": company.templateName,
                "Date Accessed": company.fullDateAccessed,
                "Billing Type": HUMAN_READABLE_BILLABLE_TYPES[company.billableType],
                "No. of Companies Accessed": company.numberOfCompaniesAccessed,
                "Client Name": company.clientName,
                "Accessed By": company.email,
                "User Notes": company.userNotes
            };
            return rowRecord;
        });
    }

    const flipRowEditState = (uniqueId) => {
        const updatedUserHistoryRecordsStateMap = new Map(userHistoryRecordsStateMap);
        const currentState = updatedUserHistoryRecordsStateMap.get(uniqueId);
        updatedUserHistoryRecordsStateMap.set(uniqueId, !currentState);
        setUserHistoryRecordsStateMap(updatedUserHistoryRecordsStateMap);
    }

    const handleRowStateUpdate = (e, uniqueId) => {
        e.preventDefault();
        flipRowEditState(uniqueId);
    }

    const createUpdateRequestBody = (event, uniqueId, userHistory) => {
        event.preventDefault();

        const index = userHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
        const userHistoryRecord = userHistory[index];

        const request = {
            uniqueId: userHistoryRecord.uniqueId,
            billableType: userHistoryRecord.billableType,
            clientName: userHistoryRecord.clientName,
            userId: userHistoryRecord.userId,
            userNotes: userHistoryRecord.userNotes,
            clientOwnerName: userHistoryRecord.clientOwnerName,
            clientOwnerEmail: userHistoryRecord.clientOwnerEmail,
            clientOwnerUserId: userHistoryRecord.clientOwnerUserId,
            templateId: userHistoryRecord.templateId,
            monthAndYearAccessed: userHistoryRecord.monthAndYearAccessed,
            dataIds: userHistoryRecord.dataIds,
            numberOfCompaniesAccessed: userHistoryRecord.numberOfCompaniesAccessed,
            nsId: userHistoryRecord?.nsId
        };
        return request
    }

    const exportUsageDowloadDataToXlsx = async (userHistory, normalizeData) => {
        const XLSX = await import("xlsx");
        const normalizedData = normalizeData(userHistory);
        const ws = XLSX.utils.json_to_sheet(normalizedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "DATA");
        XLSX.writeFile(wb, `Data_Download_History.xlsx`);
    }

    const handleHistoryRecordUpdate = async (event, uniqueId) => {
        const request = createUpdateRequestBody(event, uniqueId, userHistory);
        if (request.userNotes === "") {
            handleError("User notes are required for billable and custom billable types.");
            return;
        }
        if (request.billableType === BILLABLE_TYPES.billable || request.billableType === BILLABLE_TYPES.customBillable) {
            if (request.clientName === "") {
                handleError("Client name is required for billable and custom billable types.");
                return;
            }
            if (request.clientOwnerName.split(" ").length !== 2) {
                handleError("Client owner name is required for billable and custom billable types.");
                return;
            }
        }
        const token = await getAuthToken();
        API.put("AnnualCompensations",
            API_PATH, {
            headers: {
                'Token': token
            },
            body: request
        }).then((response) => {
            handleSettingSuccessMessage(response.message);
            flipRowEditState(uniqueId);
        }).catch((error) => {
            if (error?.response?.data?.message) {
                handleError(error.response.data.message);
            } else {
                handleError("An error occurred while updating monthly download data. Please try again later.");
            }
            console.log(error.response);
        });
        setIsFormValid(true);
    }

    const handleUserHistoryRecordsStateMap = (data) => {
        const userHistoryRecordsStateMap = new Map();
        data.forEach((userHistoryRecord) => {
            userHistoryRecordsStateMap.set(userHistoryRecord.uniqueId, false);
        });
        setUserHistoryRecordsStateMap(userHistoryRecordsStateMap);
    }

    const fetchUserDownloadHistory = async () => {
        const authToken = await getAuthToken();
        const response = await API.get("AnnualCompensations",
            API_PATH, {
            headers: {
                'Token': authToken
            }
        });
        return response;
    };

    const fetchUserHistory = async () => {
        setLoadingStatus(true);
        fetchUserDownloadHistory()
            .then((response) => {
                setLoadingStatus(false);
                if (response?.data) {
                    setUserHistory(response.data);
                    handleUserHistoryRecordsStateMap(response.data);
                } else {
                    setUserHistory([]);
                    handleUserHistoryRecordsStateMap([]);
                }
            }).catch((error) => {
                setLoadingStatus(false);
                if (error?.response?.data?.message) {
                    handleError(error.response.data.message);
                }
            });
    };

    const toggleHeaderFilters = () => {
        setShowHeaderFilters(!showHeaderFilters);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            Analytics.record({ name: 'clientOwnerHistoryModalOpened' });
            fetchUserHistory();
        } else {
            setUserHistory([]);
            setError(null);
            setShowError(false);
            setSuccess(null);
            setShowSuccess(false);
            setIsFormValid(false);
            setCurrentPage(1);
        }
        return () => { isMounted = false; }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="client-owner-download-history">
            {showError && <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                <p>
                    <strong>Error: </strong>{error}
                </p>
            </Alert>}
            {showSuccess && <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                <p>
                    <strong>Success: </strong>{success}
                </p>
            </Alert>}
            {loadingStatus ? <div className="loading">
                <output> Loading...</output>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    aria-hidden="true">
                </Spinner>

            </div> :
                <div className="user-history-container">
                    <div className="d-flex justify-content-start mb-2">
                        <Button title='Toggle Grid Header Filters' variant='primary' onClick={toggleHeaderFilters} style={{ padding: "0.25rem", maxWidth: "2rem", marginRight: "0.2rem", marginLeft: "0.1rem" }}>
                            {showHeaderFilters ? <i className="fa fa-sort-amount-up-alt" style={{ padding: "0.25rem" }}></i> : <i className="fa fa-sort-amount-down-alt" style={{ padding: "0.25rem" }}></i>}
                        </Button>
                        <Button title='Export grid to excel' variant='primary' onClick={() => exportUsageDowloadDataToXlsx(userHistory, normalizeData)} style={{ padding: "0.25rem", marginRight: "0.2rem", maxWidth: "2rem" }}>
                            <i className="fa fa-file-csv" style={{ padding: "0.25rem" }}></i>
                        </Button>
                    </div>
                    <Form noValidate validated={isFormValid} onSubmit={(e) => e.preventDefault()}>
                        <div className="user-history">
                            {userHistory.length > 0 ?
                                <SimpleGridComponent
                                    columns={columns}
                                    data={userHistory}
                                    enableRowSelection={false}
                                    showHeaderFilters={showHeaderFilters}
                                    selectedCurrentPage={currentPage}
                                    onCurrentPageSelect={(page) => setCurrentPage(page)}
                                    itemsName="User History records"
                                />
                                : <div className="no-data">No data to display</div>}
                        </div>
                    </Form>
                </div>
            }
        </div>)
};

export default ClientOwnerDataDownloadHistoryTab;