import { useCallback, useState } from 'react';
import { API } from "aws-amplify";
import { getAuthToken } from '../utils/AuthUtils';

export default function useClientMetadataSearch() {
    const [clientMetadataSearchResults, setClientMetadataSearchResults] = useState([]);
    const [loadingClientMetadata, setLoadingClientMetadata] = useState(false);

    const lookupClientMetadata = useCallback(async (searchPhrase) => {
        setLoadingClientMetadata(true);

        try {
            const token = await getAuthToken();
            const response = await API.get("AnnualCompensations", "/client-meta-data/lookup", {
                headers: { "Token": token },
                queryStringParameters: { searchPhrase: searchPhrase.toLowerCase() }
            });
            setClientMetadataSearchResults(response);
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setLoadingClientMetadata(false);
        }
    }, []);

    return {
        clientMetadataSearchResults,
        loadingClientMetadata,
        lookupClientMetadata
    };
}