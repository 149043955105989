import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { Alert } from "react-bootstrap";
import useClientMetadataSearch from "../../Hooks/useClientMetadataSearch";
import AutoCompletableInput from "../AutoCompletableInputComponent/AutoCompletableInput";

const ClientMetadataLookupInput = ({
    value,
    handleValueChange,
    noValidate = true,
    isValueValid,
    handleClientMetadataRecordSelection,
    inputLabel,
    placeholder = "",
    customErrorMessage = ""
}) => {
    const [hasError, setHasError] = useState(false);

    const {
        clientMetadataSearchResults,
        loadingClientMetadata,
        lookupClientMetadata
    } = useClientMetadataSearch();

    const handleChange = async (value) => {
        handleValueChange(value);
        if (value && value?.length >= 2) {
            try {
                await lookupClientMetadata(value);
            } catch (error) {
                console.error(error);
                setHasError(true);
            }
        }
    };

    const handleSelectResult = useCallback((selectedResultIndex) => {
        const record = clientMetadataSearchResults[selectedResultIndex];
        if (handleClientMetadataRecordSelection) {
            handleClientMetadataRecordSelection(record);
        }
        handleValueChange(record?.companyName);
    }, [clientMetadataSearchResults, handleClientMetadataRecordSelection, handleValueChange]);

    return (
        <div className='client-metadata-lookup-input'>
            <AutoCompletableInput
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                noValidate={noValidate}
                isValid={isValueValid}
                label={inputLabel}
                searchResults={clientMetadataSearchResults}
                uniqueSearchResultPropName={"nsId"}
                resultItem={"companyName"}
                loadingResults={loadingClientMetadata}
                errorMsg={customErrorMessage}
                handleSelection={(selectedIndex) => handleSelectResult(selectedIndex)}
            />
            {hasError && <Alert variant="danger">Error fetching client metadata</Alert>}
        </div>
    );
}

ClientMetadataLookupInput.propTypes = {
    value: PropTypes.string.isRequired,
    handleValueChange: PropTypes.func.isRequired,
    inputLabel: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    noValidate: PropTypes.bool,
    isValueValid: PropTypes.bool,
    handleClientMetadataRecordSelection: PropTypes.func,
    customErrorMessage: PropTypes.string
};

export default ClientMetadataLookupInput;