import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useKeyPress } from '../../Hooks/useKeyPress';

import './styles.scss';

/**
 * AutoCompletableInput component
 * @param {string} value - The value of the input field
 * @param {function} onChange - The function to call when the input field changes
 * @param {boolean} noValidate - Whether to validate the input field
 * @param {boolean} isValid - Whether the input field is valid
 * @param {string} label - The label for the input field
 * @param {array} searchResults - The results of the search
 * @param {string} uniqueSearchResultPropName - The unique property of the search result
 * @param {string} resultItem - The property of the search result to display
 * @param {boolean} loadingResults - Whether the search results are loading
 * @param {function} handleSelection - The function to call when a search result is selected
 * @returns {JSX.Element} AutoCompletableInput component
 * @example
 * @example
 * <AutoCompletableInput
 * value={value}
 * onChange={handleChange}
 * isValid={isValid}
 * label="Search"
 * searchResults={searchResults}
 * uniqueSearchResultPropName="id"
 * resultItem="name"
 * loadingResults={loadingResults}
 * handleSelection={handleSelection}
 * />
 * 
*/
const AutoCompletableInput = ({
    placeholder,
    value,
    onChange,
    noValidate = true,
    isValid,
    errorMsg = '',
    label,
    searchResults,
    uniqueSearchResultPropName,
    resultItem,
    loadingResults,
    handleSelection
}) => {
    const [showResults, setShowResults] = useState(false);
    const [elementInFocus, setElementInFocus] = useState(false);

    const initialState = { selectedIndex: 0 };

    const searchResultsRef = useRef(null);

    const reducer = (state, action) => {
        switch (action.type) {
            case 'arrowUp':
                return {
                    ...state,
                    selectedIndex:
                        state.selectedIndex !== 0
                            ? state.selectedIndex - 1
                            : searchResults.length - 1,
                };
            case 'arrowDown':
                return {
                    ...state,
                    selectedIndex:
                        state.selectedIndex !== searchResults.length - 1
                            ? state.selectedIndex + 1
                            : 0,
                };
            case 'select':
                return { ...state, selectedIndex: action.payload };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedIndex = state.selectedIndex;

    const arrowUpPressed = useKeyPress('ArrowUp');
    const arrowDownPressed = useKeyPress('ArrowDown');
    const enterPressed = useKeyPress('Enter');

    useEffect(() => {
        if (arrowUpPressed) {
            dispatch({ type: 'arrowUp' });
        }
    }, [arrowUpPressed]);

    useEffect(() => {
        if (arrowDownPressed) {
            dispatch({ type: 'arrowDown' });
        }
    }, [arrowDownPressed]);

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Enter') {
            e.preventDefault();
        }
        if (elementInFocus && value?.length > 0) {
            setShowResults(true);
        } else {
            setShowResults(false);
        }
        if (e.key === 'Escape') {
            setShowResults(false);
        }
    };

    useEffect(() => {
        if (!elementInFocus) {
            setShowResults(false);
            return;
        }
        if (enterPressed && searchResults?.length > 0) {
            handleSelection(selectedIndex);
            setShowResults(false);
        }
    }, [enterPressed, handleSelection, searchResults, selectedIndex, elementInFocus]);

    useEffect(() => {
        if (searchResultsRef.current && (arrowUpPressed || arrowDownPressed)) {
            const selectedElement = searchResultsRef.current.querySelector(
                `[data-index="${selectedIndex}"]`
            )
            if (selectedElement) {
                selectedElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });

            }
        }
    }, [arrowUpPressed, arrowDownPressed, selectedIndex])

    return (
        <div className="auto-completable-input">
            <Form.Group controlId="autoCompletableInput">
                {label && <Form.Label>{label}</Form.Label>}
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onFocus={() => setElementInFocus(true)}
                    onBlur={() => setElementInFocus(false)}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => onChange(e.target.value)}
                    isInvalid={!noValidate && !isValid}
                />
                <Form.Control.Feedback type="invalid">
                    {errorMsg}
                </Form.Control.Feedback>
            </Form.Group>
            <div
                ref={searchResultsRef}
                tabIndex={-1}
                className="search-results"
                style={{ display: showResults ? 'block' : 'none' }}
                role='search'>
                {value && searchResults?.length > 0 && !loadingResults ? (
                    <div tabIndex={-1} role='search'>
                        {searchResults.map((result, index) => (
                            <button
                                key={result[uniqueSearchResultPropName]}
                                data-index={index}
                                className={`search-result-button ${index === selectedIndex ? 'selected' : ''}`}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    handleSelection(index);
                                    setShowResults(false);
                                }}
                            >
                                <div className="search-result">
                                    <div className="col-md-8">
                                        {result[resultItem]}
                                    </div>
                                </div>
                            </button>
                        ))}
                    </div>
                ) : null}
                {searchResults?.length === 0 && !loadingResults ? (
                    <div tabIndex={-1} className="no-search-result">
                        No results found
                    </div>
                ) : null}

                {loadingResults ? (
                    <div className="search-result">
                        <Spinner as="span" animation="grow" size="sm" aria-hidden="true" />
                        <output>Loading Results...</output>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

AutoCompletableInput.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    noValidate: PropTypes.bool,
    isValid: PropTypes.bool,
    errorMsg: PropTypes.string,
    label: PropTypes.string,
    searchResults: PropTypes.array,
    uniqueSearchResultPropName: PropTypes.string,
    resultItem: PropTypes.string,
    loadingResults: PropTypes.bool,
    handleSelection: PropTypes.func.isRequired
};

export default AutoCompletableInput;