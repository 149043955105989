import { API } from 'aws-amplify';
import { useState } from "react";
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { addDays } from 'date-fns';
import { getAuthToken } from "../../utils/AuthUtils";

import { EXPORTED_DATA_REPORT_HEADERS } from '../../Constants';

const MonthlyUsageDataTab = () => {
    const [startDateString, setStartDateString] = useState("");
    const [endDateString, setEndDateString] = useState("");
    const [errorAlertStatus, setErrorAlertStatus] = useState(false)
    const [successAlertStatus, setSuccessAlertStatus] = useState(false)
    const [error, setError] = useState('')
    const [loadingStatus, setLoadingStatus] = useState(false)

    const minFirstYearDate = new Date(new Date().getFullYear(), 0, 1)
    const maxLastYearDate = new Date(new Date().getFullYear(), 11, 31)

    const clearMessage = () => {
        setError('');
        setErrorAlertStatus(false);
        setSuccessAlertStatus(false);
    }

    const createDateAndAddOneDay = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date;
    }

    const retrieveMetaData = async (e) => {
        clearMessage();
        e.preventDefault();
        if (startDateString === null
            || endDateString === null
            || startDateString === undefined
            || endDateString === undefined) {
            setError('Please select a date range');
            setErrorAlertStatus(true);
            return;
        }

        const startDate = createDateAndAddOneDay(startDateString);
        const endDate = createDateAndAddOneDay(endDateString);

        if (startDate > endDate) {
            setError('Start Date has to be more than End Date');
            setErrorAlertStatus(true);
            return;
        }
        setLoadingStatus(true);
        const formattedStartDate = startDate.getMonth() + 1;
        const formattedEndDate = endDate.getMonth() + 1;
        const authToken = await getAuthToken();
        API.get(
            "AnnualCompensations",
            "/monthly-usage-data?startMonth=" + formattedStartDate + "&endMonth=" + formattedEndDate,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Token": authToken
                }
            }).then(response => {
                exportDataToCsv(response.data);
                setLoadingStatus(false);
                setSuccessAlertStatus(true)
            }).catch(error => {
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                    setErrorAlertStatus(true);
                } else {
                    setError("An error occurred while retrieving monthly usage data. Please try again later.");
                    setErrorAlertStatus(true);
                }
                setErrorAlertStatus(true);
                setLoadingStatus(false);
            })
    }

    const exportDataToCsv = async (exportedData) => {
        const rows = exportedData.map(row => ({
            downloader: row.email,
            templateName: row.templateName,
            clientOwnerName: row.clientOwnerName,
            clientOwnerEmail: row.clientOwnerEmail,
            billableType: row.billableType,
            clientToBill: row.clientName,
            // add 1 day to the date to account for the fact that the yyyy-MM-dd format needs to be converted to a Date object
            dateDownloaded: addDays(new Date(row.fullDateAccessed), 1),
            companiesDowloaded: row?.numberOfCompaniesAccessed,
            totalRecords: row?.numberOfRecordsAccessed,
            userNotes: row.userNotes,
            adminNotes: row.adminNotes,
            negotiatedRate: row?.negotiatedRate
        }));

        const XLSX = await import('xlsx');

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data Usage Report");

        XLSX.utils.sheet_add_aoa(worksheet,
            [EXPORTED_DATA_REPORT_HEADERS],
            { origin: "A1" });

        const max_width = rows.reduce((w, r) => Math.max(w, r.clientToBill.length), 10);
        worksheet["!cols"] = [{ wch: max_width }];

        XLSX.writeFile(workbook, "Monthly_Usage_Report.xlsx");
    }

    return (
        <Form onSubmit={retrieveMetaData} style={{ margin: "1rem" }}>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="startMonth" >
                        <Form.Label>Starting Month:</Form.Label>
                        <Form.Control
                            type="date"
                            value={startDateString}
                            onChange={(e) => setStartDateString(e.target.value)}
                            min={minFirstYearDate.toISOString().split('T')[0]}
                            max={maxLastYearDate.toISOString().split('T')[0]} />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="endMonth" >
                        <Form.Label>Ending Month:</Form.Label>
                        <Form.Control
                            type="date"
                            value={endDateString.toLocaleString('default', { month: 'long', year: 'numeric' })}
                            onChange={(e) => setEndDateString(e.target.value)}
                            min={minFirstYearDate.toISOString().split('T')[0]}
                            max={maxLastYearDate.toISOString().split('T')[0]} />
                    </Form.Group>
                </Col>
            </Row>
            {errorAlertStatus && <Row style={{ "marginTop": "0.5rem", "padding": "0 1rem" }}>
                <Alert variant="danger" onClose={() => setErrorAlertStatus(false)} style={{ "marginBottom": "0.3rem" }} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                        {error}
                    </p>
                </Alert>
            </Row>}
            {successAlertStatus && <Row style={{ "marginTop": "0.5rem", "padding": "0 1rem" }}>
                <Alert variant="success" onClose={() => setSuccessAlertStatus(false)} style={{ "padding": "0.5rem", "marginBottom": "0.3rem" }} dismissible>
                    <Alert.Heading>Success!</Alert.Heading>
                </Alert>
            </Row>}
            <Row style={{ "marginTop": "0.5rem" }}>
                <Col>
                    <Button type='submit' style={{ "padding": "0.5rem" }} disabled={loadingStatus} variant='primary'>Submit</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default MonthlyUsageDataTab;