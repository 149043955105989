import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

const GroupVisibleComponent = ({ groups, styles, children }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const checkUserGroups = async () => {
            if (groups) {
                const user = await Auth.currentAuthenticatedUser();
                let userGroups = user.signInUserSession.accessToken.payload["cognito:groups"];
                let userInAConfiguredGroups = userGroups?.some(group => groups.includes(group));
                setVisible(userInAConfiguredGroups);
            }
        };

        checkUserGroups();
    }, [groups]);

    if (visible) {
        return styles ? (
            <div style={styles}>
                {children}
            </div>
        ) : (
            <>
                {children}
            </>
        );
    } else {
        return null;
    }
};

export default GroupVisibleComponent;

GroupVisibleComponent.propTypes = {
    groups: PropTypes.array,
    styles: PropTypes.object,
    children: PropTypes.node.isRequired
};