import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { getAuthToken } from "../../utils/AuthUtils";
import { compressDownloadRequestData } from '../../utils/WebSocketUtils';

import { BILLABLE_TYPES, HUMAN_READABLE_BILLABLE_TYPES } from '../../Constants';
import { ToastContext } from '../../Contexts/ToastProvider';
import ClientMetadataLookupInput from '../ClientMetadataLookupInputComponent/ClientMetadataLookupInput';
import UserLookupInput from '../UserLookupInputComponent/UserLookupInput';

import './styles.scss';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_API;

const ClientNameForDataExport = ({ show, onClose, compensationIds, selectedTemplate }) => {
  const [billableType, setBillableType] = useState('');
  const [isBillableTypeSelected, setIsBillableTypeSelected] = useState(true)
  const [requiredNonBillable, setRequiredNonBillable] = useState(false);

  const [userNotes, setUserNotes] = useState('');
  const [characterLimit, setCharacterLimit] = useState(250);

  const [downloading, setDownloading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [clientOwnerNameLookupTerm, setClientOwnerNameLookupTerm] = useState("");
  const [selectedClientOwnerUserRecord, setSelectedClientOwnerUserRecord] = useState({});
  const [clientOwnerNameValid, setClientOwnerNameValid] = useState(true);
  const [clientOwnerNameErrorMessage, setClientOwnerNameErrorMessage] = useState("");

  const [clientName, setClientName] = useState("");
  const [nsId, setNsId] = useState("");
  const [clientNameInvalid, setClientNameInvalid] = useState(false);

  const { showToast } = useContext(ToastContext);

  const resetState = () => {
    setIsBillableTypeSelected(true);
    setClientNameInvalid(false);
    setRequiredNonBillable(false);
    setClientOwnerNameValid(true);
    setClientOwnerNameErrorMessage("");
    setShowError(false);
    setErrorMessage("");
  }

  const handleSubmit = async (e) => {
    resetState();

    if (billableType === "") {
      setIsBillableTypeSelected(false)
      return;
    } else {
      setIsBillableTypeSelected(true);
    }

    if (billableType === BILLABLE_TYPES.billable || billableType === BILLABLE_TYPES.customBillable) {
      if ((!clientName || clientName.length < 2 || clientName.trim() === "")) {
        setClientNameInvalid(true)
        return;
      } else {
        setClientNameInvalid(false);
      }

      if (clientOwnerNameLookupTerm === "" || clientOwnerNameLookupTerm === undefined || clientOwnerNameLookupTerm.trim() === ""
        || clientOwnerNameLookupTerm === null || clientOwnerNameLookupTerm.trim().split(" ").length < 2) {
        setClientOwnerNameErrorMessage('Client Owner Name should be in the format "First Last"');
        setClientOwnerNameValid(false);
        return;
      }

      if (selectedClientOwnerUserRecord === undefined || selectedClientOwnerUserRecord === null
        || Object.keys(selectedClientOwnerUserRecord).length === 0) {
        setErrorMessage("Please select a client owner");
        setShowError(true);
        return;
      }
    }

    if (userNotes.length === 0 && billableType !== BILLABLE_TYPES.billable && billableType !== BILLABLE_TYPES.customBillable) {
      setRequiredNonBillable(true);
      return;
    }

    setIsBillableTypeSelected(true);
    setClientOwnerNameValid(true);
    setClientNameInvalid(false);
    setRequiredNonBillable(false);

    await handleDownload(billableType, userNotes);
  }

  const validateSelection = () => {
    if (compensationIds.size === 0) {
      setShowError(true);
      setErrorMessage("Please select at least one row to download");
      return false;
    }
    return true;
  };

  const prepareWebSocketConnection = async (request) => {
    const authToken = await getAuthToken();
    const url = `${WEBSOCKET_URL}/?token=${authToken}`;
    const socket = new WebSocket(url);

    socket.addEventListener("open", () => handleWebSocketOpen(socket, request));
    socket.addEventListener("error", () => handleWebSocketError(socket));
    socket.addEventListener("close", handleWebSocketClose);
    socket.addEventListener("message", (event) => handleWebSocketMessage(socket, event));

    return socket;
  };

  const handleWebSocketOpen = (socket, request) => {
    const eventStr = compressDownloadRequestData(request);
    const textEncoder = new TextEncoder();
    if (textEncoder.encode(eventStr).length > 128000) {
      handleError("Download request is too large. Please select fewer rows or tickers.");
      socket.close();
    } else {
      socket.send(eventStr);
    }
  };

  const handleWebSocketError = (socket) => {
    handleError("An error occurred downloading data");
    console.log("WebSocket error");
    socket.close();
  };

  const handleWebSocketClose = () => {
    console.log("WebSocket connection closed");
  };

  const handleWebSocketMessage = (socket, event) => {
    const data = JSON.parse(event?.data);
    if (data?.url) {
      handleSuccess(data.url);
      socket.close();
    } else if (data?.error) {
      handleError(data.error);
      socket.close();
    }
  };

  const handleSuccess = (url) => {
    handlDownloadUrl(url);
    setDownloading(false);
    showToast(`${selectedTemplate.templateName} data downloaded successfully`);
    handleClose();
  };

  const handleError = (errorMessage) => {
    setDownloading(false);
    setShowError(true);
    setErrorMessage(errorMessage);
  };

  const handleDownload = async (billableType, userNotes) => {
    if (!validateSelection()) return;

    setErrorMessage(null);
    setShowError(false);
    setDownloading(true);

    const request = {
      selectedCompensationIds: [...compensationIds],
      templateId: selectedTemplate.templateId,
      billableType,
      clientName,
      nsId,
      userNotes,
      clientOwnerName: selectedClientOwnerUserRecord.userName,
      clientOwnerUserId: selectedClientOwnerUserRecord.userId,
      clientOwnerEmail: selectedClientOwnerUserRecord.userEmail,
    };

    try {
      await prepareWebSocketConnection(request);
    } catch (error) {
      handleError("An error occurred while downloading data. Please try again later.");
    }
  }

  const handlDownloadUrl = (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const handleChangeBillableType = (event) => {
    setBillableType(event.target.value);
    setIsBillableTypeSelected(true);
    setClientNameInvalid(false);
    setRequiredNonBillable(false);
    setClientName("");
    setSelectedClientOwnerUserRecord({});
  }

  const handleNotesEntry = (event) => {
    setUserNotes(event.target.value);
    const limit = 250 - event.target.value.length
    setCharacterLimit(limit);
    setRequiredNonBillable(false);
  }

  const handleSettingClientMetadata = (clientMetadata) => {
    setClientName(clientMetadata?.companyName);
    setNsId(clientMetadata?.nsId);
    handleSetClientOwner(clientMetadata);
  }

  const handleSetClientOwner = (clientMetadata) => {
    if (clientMetadata?.clientOwner && clientMetadata?.clientOwnerId && clientMetadata?.clientOwnerEmail) {
      setClientOwnerNameLookupTerm(clientMetadata?.clientOwner);
      const clientOwnerData = {
        userName: clientMetadata?.clientOwner,
        userId: clientMetadata?.clientOwnerId,
        userEmail: clientMetadata?.clientOwnerEmail
      };
      setSelectedClientOwnerUserRecord(clientOwnerData);
    } else {
      setClientOwnerNameLookupTerm("");
      setSelectedClientOwnerUserRecord({});
    }
  };

  const handleClose = () => {
    setBillableType('');
    setIsBillableTypeSelected(true);
    setRequiredNonBillable(false);

    setUserNotes('');
    setCharacterLimit(250);

    setShowError(false);
    setErrorMessage('');

    setClientNameInvalid(false);
    setClientName("");

    setSelectedClientOwnerUserRecord({});
    setClientOwnerNameLookupTerm("");
    setClientOwnerNameValid(true);
    setClientOwnerNameErrorMessage("");

    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="md" contentClassName="modal-container">
      <Modal.Header closeButton>
        <h5 className='mb-0'>Data Billing</h5>
      </Modal.Header>
      <Form
        noValidate
        id="clientNameforDataExportForm"
        onSubmit={(event) => { event.preventDefault(); event.stopPropagation(); handleSubmit(event); }}>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label>Billable Type</Form.Label>
            <Form.Select onChange={(event) => handleChangeBillableType(event)} isInvalid={!isBillableTypeSelected}>
              <option value="">Select a value</option>
              <option value={BILLABLE_TYPES.billable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.billable]}</option>
              <option value={BILLABLE_TYPES.customBillable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.customBillable]}</option>
              <option value={BILLABLE_TYPES.nonBillableRFP}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableRFP]}</option>
              <option value={BILLABLE_TYPES.nonBillableOther}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableOther]}</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Control.Feedback type="invalid">Please select a billable type</Form.Control.Feedback>
            {(billableType === BILLABLE_TYPES.billable || billableType === BILLABLE_TYPES.customBillable) ?
              <>
                <ClientMetadataLookupInput
                  value={clientName}
                  handleValueChange={setClientName}
                  handleClientMetadataRecordSelection={handleSettingClientMetadata}
                  noValidate={false}
                  inputLabel="Client Name"
                  placeholder="Please enter client name"
                  isValueValid={!clientNameInvalid}
                  customErrorMessage="Please enter client name to bill"
                />
                <UserLookupInput
                  value={clientOwnerNameLookupTerm}
                  handleValueChange={setClientOwnerNameLookupTerm}
                  handleUserRecordSelection={setSelectedClientOwnerUserRecord}
                  placeholder='Please enter client owner name'
                  inputLabel="FWC Client Owner Name"
                  noValidate={false}
                  isValueValid={clientOwnerNameValid}
                  customErrorMessage={clientOwnerNameErrorMessage}
                />
              </> : ""}
          </Form.Group>
          <Form.Group className="mb-4" controlId="clientNameforDataExportForm.userNotesInput">
            <Form.Label>User Notes</Form.Label>
            <Form.Control
              placeholder="Please enter any notes for this download"
              onChange={(event) => handleNotesEntry(event)}
              as="textarea"
              maxLength="250"
              isInvalid={requiredNonBillable}
            />
            <span className='character-limit-count'>Character limit: {characterLimit}</span>
            <Form.Control.Feedback type="invalid">
              User Notes required for non-billable downloads
            </Form.Control.Feedback>
          </Form.Group>
          {showError ? <div className="alert alert-danger" role="alert">{errorMessage}</div> : null}
        </Modal.Body>
        <Modal.Footer>
          {downloading ?
            <Button variant="btn btn-primary"
              disabled={true}>
              <output>Downloading...</output>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                aria-hidden="true"
              />
            </Button> :
            <Button variant="btn btn-primary"
              type="submit"
              style={{ "width": "25%" }}>
              Download
            </Button>}
          <Button variant="btn btn-secondary" onClick={handleClose} style={{ "width": "20%" }} disabled={downloading}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal >
  );
}

ClientNameForDataExport.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  compensationIds: PropTypes.instanceOf(Set),
  selectedTemplate: PropTypes.object
};

export default ClientNameForDataExport;