import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Alert, Badge, Button, Col, Form, Row } from "react-bootstrap";
import { getAuthToken } from "../../utils/AuthUtils";

import { useNavigate } from "react-router-dom";
import { useStateStore } from "../../AppState";
import ToolTipWrapper from "../../CommonComponents/ToolTipComponent/ToolTipWrapper";
import BulkCompanyRequestModal from "../BulkCompanyRequestModal/BulkCompanyRequestModal";
import CompanyRequestsGrid from "../CompanyRequestsGrid/CompanyRequestsGrid";
import DataSubmissionConfirmationModal from "../DataSubmissionConfirmationModal/DataSubmissionConfirmationModal";

import "./styles.scss";

const DataRequestSubmission = () => {
    const navigate = useNavigate();

    const { setLoadingStatus } = useStateStore();

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [showCompanyIdsModal, setShowCompanyIdsModal] = useState(false);
    const [showDataSubmissionConfirmationModal, setShowDataSubmissionConfirmationModal] = useState(false);

    const [formIsValidated, setFormIsValidated] = useState(false);
    const [clientName, setClientName] = useState('');
    const [clientTicker, setClientTicker] = useState('');

    const [deadline, setDeadline] = useState('');

    const [activeTemplates, setActiveTemplates] = useState([]);
    const [companyRequests, setCompanyRequests] = useState([]);
    const [areCompanyRequestsValid, setAreCompanyRequestsValid] = useState(true);

    const [latestTemplateDataAvailable, setLatestTemplateDataAvailable] = useState(new Map());

    const fetchCompanyName = async (companyId) => {
        const authToken = await getAuthToken();
        try {
            const response = await API.get("AnnualCompensations", `/lookup-company-name/${companyId}`, {
                headers: {
                    "Token": authToken
                }
            });
            if (response === undefined || response === null) return "";
            return response.name;
        } catch (error) {
            console.log(error);
        }
    }

    const prepopulateCompanyNames = async (companyRequests) => {
        const requests = [];
        setLoadingStatus(true);
        for (const request of companyRequests) {
            const companyName = await fetchCompanyName(request.companyId);
            requests.push({
                ...request,
                companyName: companyName
            });
        }
        setLoadingStatus(false);
        return requests;
    }

    const buildCompanyRequests = (companyIds, bulkCompanyAnalysisType) => {
        const requests = [];
        companyIds.forEach(companyId => {
            requests.push({
                companyId: companyId,
                templateId: bulkCompanyAnalysisType,
            });
        });
        return requests;
    }

    const handleInitializingCompanyRequests = async (companyIds, bulkCompanyAnalysisType) => {
        setErrorMessage('');
        setShowErrorMessage(false);
        setSuccessMessage('');
        setShowSuccessMessage(false);

        const requests = buildCompanyRequests(companyIds, bulkCompanyAnalysisType);
        const uniqueRequests = [];
        requests.forEach(request => {
            const existingRequest = companyRequests.find(cr => cr.companyId === request.companyId && cr.templateId === request.templateId);
            if (existingRequest === undefined) {
                uniqueRequests.push(request);
            }
        });

        const prepopulatedRequests = await prepopulateCompanyNames(uniqueRequests);
        const completeList = [...companyRequests, ...prepopulatedRequests];
        setCompanyRequests(completeList);
    }

    const handleClosingCompanyIdsModal = () => {
        setShowCompanyIdsModal(false);
    }

    const resetCompanyRequestAlerts = () => {
        const updatedCompanyRequests = [];
        companyRequests.forEach(request => {
            if (request.feedback) {
                request.feedback = undefined;
            }
            updatedCompanyRequests.push({
                ...request,
                hasAlert: false,
                alertMessage: ''
            });
        });
        return updatedCompanyRequests;
    }

    const handleCompanyRequestResponse = (response) => {
        if (response?.success) {
            setSuccessMessage('Company requests successfully added.');
            setShowSuccessMessage(true);
            setLatestTemplateDataAvailable(new Map());
            setTimeout(() => {
                navigate('/data-requests/user');
            }, 1500);
        } else {
            setShowErrorMessage(true);
            setErrorMessage("Your request has errors. Please remove the companies with errors and try again.");
        }
    }

    const fetchLatestDataAvailable = async (companyRequests) => {
        const authToken = await getAuthToken();
        const tickerByTemplate = new Map();
        const latestDataAvailable = new Map();
        companyRequests.forEach(request => {
            const templateId = request.templateId;
            if (tickerByTemplate.has(templateId)) {
                tickerByTemplate.get(templateId).push(request.companyId);
            } else {
                tickerByTemplate.set(templateId, [request.companyId]);
            }
        });
        try {
            for (const [templateId, companyIds] of tickerByTemplate) {
                const path = `/companies/data/latest/availability?templateId=${templateId}&companyIds=${companyIds.map(encodeURIComponent).join(',')}`;
                const response = await API.get("AnnualCompensations", path, {
                    headers: {
                        'Token': authToken
                    }
                });
                if (response === undefined || response === null || response.length === 0) continue;
                latestDataAvailable.set(templateId, response);
            }
            return latestDataAvailable;

        } catch (error) {
            console.log(error);
            setErrorMessage("There was an error fetching the latest data available. Please try again later.");
        }
    }

    const handleCompanyRequestsSubmit = async (e, confirmSubmission) => {
        setLoadingStatus(true);
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setSuccessMessage('');
        setErrorMessage('');
        setWarningMessage('');
        setShowWarningMessage(false);
        setLatestTemplateDataAvailable(new Map());
        setShowDataSubmissionConfirmationModal(false);

        e.preventDefault();
        if (e.target.checkValidity() === false) {
            setLoadingStatus(false);
            setErrorMessage("Please correct the form before submitting.");
            setShowErrorMessage(true);
            e.stopPropagation();
        } else {
            if (areCompanyRequestsValid === false) {
                setShowErrorMessage(true);
                setErrorMessage("Please correct the company requests before submitting.");
                setLoadingStatus(false);
                return;
            }

            const updatedCompanyRequests = resetCompanyRequestAlerts();
            setCompanyRequests(updatedCompanyRequests);

            if (!confirmSubmission) {
                const latestDataAvailable = await fetchLatestDataAvailable(updatedCompanyRequests);
                if (latestDataAvailable.size > 0) {
                    setLatestTemplateDataAvailable(latestDataAvailable);
                    setShowDataSubmissionConfirmationModal(true);
                    setLoadingStatus(false);
                    return;
                }
            }

            const authToken = await getAuthToken();
            const dataRequest = {
                clientName: clientName,
                clientCompanyId: clientTicker,
                deadline: deadline,
                companiesRequested: updatedCompanyRequests,
            }
            API.post("AnnualCompensations",
                "/data-requests",
                {
                    headers: {
                        'Token': authToken
                    },
                    body: dataRequest
                }).then(response => {
                    handleCompanyRequestResponse(response);
                    setLoadingStatus(false);
                }
                ).catch(error => {
                    console.log(error);
                    setLoadingStatus(false);
                    setShowErrorMessage(true);
                    setErrorMessage("Error submitting data request. Please try again later.");
                });
        }
        setFormIsValidated(true);
    }

    const removeCompanyRequest = (position) => {
        const updatedCompanyRequests = [...companyRequests];
        updatedCompanyRequests.splice(position, 1);
        setCompanyRequests(updatedCompanyRequests);
    }

    const handleSettingCompanyRequestDeadline = (e) => {
        const today = new Date();
        const deadline = new Date(e.target.value);
        if (deadline < today || deadline.getFullYear() < today.getFullYear())
            return;
        setDeadline(e.target.value);
    }

    const reset = () => {
        setFormIsValidated(false);
        setAreCompanyRequestsValid(false);
        setErrorMessage('');
        setShowErrorMessage(false);
        setSuccessMessage('');
        setShowSuccessMessage(false);
        setClientName('');
        setClientTicker('');
        setCompanyRequests([]);
        setWarningMessage('');
        setShowWarningMessage(false);
        setLatestTemplateDataAvailable(new Map());
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const retrieveTemplateNames = async () => {
                setLoadingStatus(true);
                const authToken = await getAuthToken();
                API.get("AnnualCompensations",
                    "/research-analysis/template-names",
                    {
                        headers: {
                            'Token': authToken
                        }
                    }).then(response => {
                        setActiveTemplates(response.data);
                        setLoadingStatus(false);
                    }
                    ).catch(error => {
                        console.log(error);
                        setLoadingStatus(false);
                    });
            }
            retrieveTemplateNames();
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="data-request-submission">
            <BulkCompanyRequestModal
                showModal={showCompanyIdsModal}
                onClose={() => handleClosingCompanyIdsModal()}
                handleCompanyRequests={(companyIds, selectedAnalysisType) =>
                    handleInitializingCompanyRequests(companyIds, selectedAnalysisType)
                }
                activeTemplates={activeTemplates}
            />
            <div className="data-request-submission-container">
                <h2 className="header-title">Data Request</h2>
                {showSuccessMessage && <Alert variant="success" onClose={() => setShowSuccessMessage(false)} dismissible>{successMessage}</Alert>}
                {showWarningMessage && <Alert variant="warning" onClose={() => setShowWarningMessage(false)} dismissible>{warningMessage}</Alert>}
                {showErrorMessage && <Alert variant="danger" onClose={() => setShowErrorMessage(false)} dismissible>{errorMessage}</Alert>}
                <Form noValidate validated={formIsValidated} onSubmit={(e) => handleCompanyRequestsSubmit(e, false)}>
                    <DataSubmissionConfirmationModal
                        show={showDataSubmissionConfirmationModal}
                        handleClose={() => setShowDataSubmissionConfirmationModal(false)}
                        latestTemplateDataAvailable={latestTemplateDataAvailable}
                        handleDataRequestSubmission={(e) => handleCompanyRequestsSubmit(e, true)}
                        activeTemplates={activeTemplates}
                    />
                    <div className="data-request-inputs">
                        <Row style={{ marginTop: "0.5rem" }}>
                            <Col md={4}>
                                <Form.Group controlId="clientName">
                                    <Form.Label>Client Name</Form.Label>
                                    <ToolTipWrapper
                                        id={"clientName"}
                                        placement={"top"}
                                        text={"Name of the client data is being requested for."}
                                        element={<i className="fas fa-info-circle" style={{ marginLeft: "0.5rem" }}></i>}
                                    />
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter client name"
                                        value={clientName}
                                        onChange={(e) => setClientName(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a client name
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="clientTicker">
                                    <Form.Label>Client Ticker / Company ID</Form.Label>
                                    <ToolTipWrapper
                                        id={"clientTicker"}
                                        placement={"top"}
                                        text={"Ticker or company ID of the client data is being requested for."}
                                        element={
                                            <i className="fas fa-info-circle" style={{ marginLeft: "0.5rem" }}></i>
                                        }
                                    />
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter client ticker"
                                        value={clientTicker}
                                        onChange={(e) => setClientTicker(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a client ticker
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "0.5rem" }}>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Deadline</Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        min={new Date().getFullYear() + "-01-01"}
                                        value={deadline}
                                        onChange={handleSettingCompanyRequestDeadline}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="company-requests">
                        <Row>
                            <div className="company-requests__header">
                                <div className="company-requests__header__title">
                                    <h4 className="header-title">Companies by Analysis Type</h4>
                                    <div className="company-requests__header__title__badge">
                                        <ToolTipWrapper
                                            id="company-requests-count-tooltip"
                                            placement="top"
                                            text="Number of companies in the request"
                                            element={<Badge variant="primary">{companyRequests.length}</Badge>}
                                        />
                                    </div>
                                </div>
                                <div className="company-requests__header__add-button">
                                    <ToolTipWrapper
                                        id="add-company-requests-tooltip"
                                        placement="top"
                                        text="Add companies to the request"
                                        element={
                                            <Button onClick={() => setShowCompanyIdsModal(true)}>
                                                <i className="fa fa-2xl fa-plus-circle"></i>
                                            </Button>
                                        }
                                    />
                                </div>
                            </div>
                        </Row>
                        {companyRequests.length > 0 ?
                            <CompanyRequestsGrid
                                companyRequests={companyRequests}
                                onCompanyRequestsChange={(updatedCompanyRequests) => setCompanyRequests(updatedCompanyRequests)}
                                onRequestValidityChange={(validity) => setAreCompanyRequestsValid(validity)}
                                activeTemplates={activeTemplates}
                                onRemoveCompanyRequest={(position) => removeCompanyRequest(position)}
                            />
                            : null}
                    </div>
                    <div className="data-request-buttons">
                        <Button variant="secondary" onClick={() => reset()}>Reset</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default DataRequestSubmission;