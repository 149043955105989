import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import {
	Alert, Button, Col, Form, Row,
} from 'react-bootstrap';
import { useStateStore } from '../../AppState.js';
import ClientMetadataLookupInput from '../../CommonComponents/ClientMetadataLookupInputComponent/ClientMetadataLookupInput.js';
import UserLookupInput from '../../CommonComponents/UserLookupInputComponent/UserLookupInput.js';
import {  BILLABLE_TYPES, HUMAN_READABLE_BILLABLE_TYPES } from '../../Constants.js';
import GenericGridComponent from '../../GenericGridComponent/GenericGridComponent.js';
import { getAuthToken } from '../../utils/AuthUtils.js';
import BillingDataModal from './BillingDataModal/BillingDataModal.js';

import './styles.scss';

const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;
const twoYearsAgo = currentYear - 2;
const currentMonth = new Date().getMonth() + 1;

const BillingDataTab = () => {

	const [billingData, setBillingData] = useState([]);
	const [year, setYear] = useState(currentYear);
	const [billingCycle, setBillingCycle] = useState(currentMonth);
	const [billableType, setBillableType] = useState('');

	const [clientName, setClientName] = useState('');

	const { setLoadingStatus } = useStateStore();
	const [hasError, setHasError] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);

	const [showSearchResults, setShowSearchResults] = useState(false);
	const [selectedDownloadUserRecord, setSelectedDownloadUserRecord] = useState({});

	const [selectedBillingRecord, setSelectedBillingRecord] = useState(null);
	const [showBillingRecordModal, setShowBillingRecordModal] = useState(false);
	const [billingRecordMode, setBillingRecordMode] = useState('create');

	const [clientOwnerUserLookupTerm, setClientOwnerUserLookupTerm] = useState('');
	const [selectedClientOwnerUserRecord, setSelectedClientOwnerUserRecord] = useState({});

	const [downloadUserLookupTerm, setDownloadUserLookupTerm] = useState('');

	const billingCycleList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	const actions = row => (
		<div className='edit-record'>
			<Button variant='primary edit-billing-record-btn' size='sm' className='mr-2' onClick={() => handleEditBillingRecord(row)}>
				<i className='fa fa-edit' title='Edit'></i>
			</Button>
		</div>
	);

	const handleEditBillingRecord = billingRecord => {
		setSelectedBillingRecord(billingRecord);
		setShowBillingRecordModal(true);
		setBillingRecordMode('edit');
	};

	const handleSearch = async () => {
		try {
			setLoadingStatus(true);
			setHasError(false);
			const authToken = await getAuthToken();
			const queryStringParameters = {
				internalBillingCycle: billingCycle,
				year,
				billableType,
				clientName,
				clientOwnerEmail: selectedClientOwnerUserRecord.userEmail,
				userEmail: selectedDownloadUserRecord.userEmail,
			};

			API.get('AnnualCompensations', '/billing-data', {
				headers: {
					'Content-Type': 'application/json',
					Token: authToken,
				},
				queryStringParameters,
			}).then(response => {
				setBillingData(response);
				setLoadingStatus(false);
				setShowSearchResults(true);
				setHasError(false);
			}).catch(error => {
				console.log(error);
				setHasError(true);
				setLoadingStatus(false);
				setShowSearchResults(false);
			});
		} catch (error) {
			console.log(error);
			setHasError(true);
			setLoadingStatus(false);
			setShowSearchResults(false);
		}
	};

	const columns = [
		{
			key: 'actions',
			name: "Edit",
			sortable: false,
			filterable: false,
			formatter: ({ row }) => (
				actions(row)
			),
			minWidth: 100,
			frozen: true,
		},
		{
			key: 'clientName',
			name: 'Client Name',
			sortable: true,
			filterable: true,
			frozen: true,
			minWidth: 120,
		},
		{
			key: 'billableType',
			name: 'Billable Type',
			sortable: true,
			filterable: true,
			formatter: ({ row }) => HUMAN_READABLE_BILLABLE_TYPES[row.billableType],
			frozen: true,
			minWidth: 150,
		},
		{
			key: 'fullDateAccessed',
			name: 'Date Accessed',
			sortable: true,
			filterable: true,
			frozen: true,
			minWidth: 150,
		},
		{
			key: 'numberOfCompaniesAccessed',
			name: 'No. Companies',
			sortable: true,
			filterable: true,
			minWidth: 150,
		},
		{
			key: 'templateName',
			name: 'Template Name',
			sortable: true,
			filterable: true,
			minWidth: 150,
		},
		{
			key: 'clientOwnerName',
			name: 'Client Owner Name',
			sortable: true,
			filterable: true,
			minWidth: 200,
		},
		{
			key: 'userEmail',
			name: 'Downloaded By',
			sortable: true,
			filterable: true,
			minWidth: 200,
		},
		{
			key: 'negotiatedRate',
			name: 'Negotiated Rate',
			sortable: true,
			filterable: true,
			minWidth: 150,
		},
		{
			key: 'userNotes',
			name: 'User Notes',
			sortable: true,
			filterable: true,
			minWidth: 150,
		},
		{
			key: 'adminNotes',
			name: 'Admin Notes',
			sortable: true,
			filterable: true,
			minWidth: 150,
		},
		{
			key: 'modifiedBy',
			name: 'Modified By',
			sortable: true,
			filterable: true,
			minWidth: 150,
		},
		{
			key: 'modifiedOn',
			name: 'Modified Date',
			sortable: true,
			filterable: true,
			minWidth: 150,
		}
	];

	const clearSearchParameters = () => {
		setYear(currentYear);
		setBillingCycle(currentMonth);
		setBillableType('');
		setClientName('');
		setSelectedClientOwnerUserRecord({});
		setDownloadUserLookupTerm('');
		setSelectedDownloadUserRecord({});
	};

	useEffect(() => {
		const mounted = true;
		if (mounted) {
			setBillingData([]);
			setYear(currentYear);
			setBillingCycle(currentMonth);
			setBillableType('');
			setClientName('');
			setLoadingStatus(false);
			setCurrentPage(1);
			setHasError(false);
			setShowSearchResults(false);
			setSelectedClientOwnerUserRecord({});
			setDownloadUserLookupTerm('');
			setSelectedDownloadUserRecord({});
		}
		// eslint-disable-next-line
	}, [])

	return (
		<div className='billing-data-container'>
			<Form onSubmit={e => e.preventDefault()}>
				<div className='search-parameters'>
					<Row>
						<Col md={4}>
							<Form.Label>Year</Form.Label>
							<Form.Select value={year} onChange={e => setYear(e.target.value)}>
								{new Date().getMonth() === 11 && new Date().getDate() > 25 && <option value={currentYear + 1}>{currentYear + 1}</option>}
								<option value={currentYear}>{currentYear}</option>
								<option value={lastYear}>{lastYear}</option>
								<option value={twoYearsAgo}>{twoYearsAgo}</option>
							</Form.Select>
						</Col>
						<Col md={4}>
							<Form.Label>Billing Cycle</Form.Label>
							<Form.Select as='select' value={billingCycle} onChange={e => setBillingCycle(e.target.value)}>
								{billingCycleList.map(month => <option key={month} value={month}>{month}</option>)}
							</Form.Select>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<Form.Label>Billable Type</Form.Label>
							<Form.Select value={billableType} onChange={e => setBillableType(e.target.value)}>
								<option value={''}>Select Billable Type</option>
								<option value={BILLABLE_TYPES.billable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.billable]}</option>
								<option value={BILLABLE_TYPES.customBillable}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.customBillable]}</option>
								<option value={BILLABLE_TYPES.nonBillableRFP}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableRFP]}</option>
								<option value={BILLABLE_TYPES.nonBillableOther}>{HUMAN_READABLE_BILLABLE_TYPES[BILLABLE_TYPES.nonBillableOther]}</option>
							</Form.Select>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<ClientMetadataLookupInput
								value={clientName}
								handleValueChange={setClientName}
								inputLabel={'Client Name'}
								placeholder='Search by Client name'
							/>
						</Col>
						<Col md={4}>
							<UserLookupInput
								value={clientOwnerUserLookupTerm}
								handleValueChange={setClientOwnerUserLookupTerm}
								noValidate={true}
								inputLabel={'FWC Client Owner Name'}
								placeholder='Search by name'
								handleUserRecordSelection={setSelectedClientOwnerUserRecord}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<UserLookupInput
								value={downloadUserLookupTerm}
								handleValueChange={setDownloadUserLookupTerm}
								noValidate={true}
								inputLabel={'Downloaded By'}
								placeholder='Search by name'
								handleUserRecordSelection={setSelectedDownloadUserRecord}
							/>
						</Col>
					</Row>
					<div className='search-buttons'>
						<Col md={3}>
							<Button variant='primary' type='submit' onClick={handleSearch}>Search<i className='fa fa-search'></i></Button>
							<Button variant='secondary' onClick={clearSearchParameters}>Reset</Button>
							<Button
								title="Add New Billing Record"
								variant='secondary'
								style={{ padding: "0.25rem", maxWidth: "2.5rem", marginRight: "0.2rem", borderRadius: "50%" }}
								onClick={() => {
									setShowBillingRecordModal(true)
									setBillingRecordMode('create')
								}}
							>
								<i className="fa fa-plus" style={{ padding: "0.25rem" }}></i>
							</Button>
						</Col>
					</div>
				</div>
			</Form>
			<div className='search-results-container'>
				{(showBillingRecordModal && billingRecordMode === 'edit') ? <BillingDataModal
					data={selectedBillingRecord}
					show={showBillingRecordModal}
					onClose={() => {
						setShowBillingRecordModal(false);
						setSelectedBillingRecord(null);
					}}
					onRecordUpdated={() => handleSearch()}
					mode={billingRecordMode}
				/> : <BillingDataModal
					show={showBillingRecordModal}
					onClose={() => {
						setShowBillingRecordModal(false);
						setSelectedBillingRecord(null);
					}}
					onRecordUpdated={() => handleSearch()}
					mode={billingRecordMode}
				/>}
				<div className='billing-data-grid'>
					{hasError && <Alert variant='danger' dismissible>Error retrieving data</Alert>}
					{showSearchResults ? <GenericGridComponent
						columns={columns}
						data={billingData}
						enableGridToCSV={true}
						useCustomExportMethod={false}
						fileName={'Billing_Data'}
						itemsName={'Billing Data'}
						selectedCurrentPage={currentPage}
						onCurrentPageSelect={page => setCurrentPage(page)}
					/> : ''}
				</div>
			</div>
		</div>
	);
};

export default BillingDataTab;
